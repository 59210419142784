import get from 'lodash/get'
import { useMapStore } from '~/stores/map'
import { useMatchmakingDataStore } from '~/stores/matchmaking'
import { useStationDataStore } from '~/stores/stations'
import { useStatisticsStore } from '~/stores/statistics'
import { Filter, FilterPreset, FilterPresets, FilterSettings } from '~/types/filters'
import { FuelType, Station } from '~/types/station'
import { MatchmakingItem } from '~/types/types'

export function useGlobalFilters() {
  const stationsStore = useStationDataStore()
  const matchmakingStore = useMatchmakingDataStore()
  const statisticsStore = useStatisticsStore()
  const mapStore = useMapStore()

  function applyFilterPreset(preset: FilterPreset) {
    stationsStore.resetFilters()
    stationsStore.setDefaultExludeElectricityOrigin()
    matchmakingStore.resetFilters()

    if (!preset) return

    preset.stations.forEach((filter) => {
      //   stationsStore.updateFilter(filter.filterIdentifier, filter.values)
      updateFilterValues(filter.filterIdentifier, filter.values)
    })

    preset.matchmaking.forEach((filter) => {
      //   matchmakingStore.updateFilter(filter.filterIdentifier, filter.values)
      updateFilterValues(filter.filterIdentifier, filter.values)
    })
  }

  function evalFiltersOnItem(
    item: MatchmakingItem | Station,
    filters: Filter[],
    filterSettings: FilterSettings
  ) {
    // console.log('evalFiltersOnItem', item, filters, filterSettings)
    return filters.every((filter) => {
      const filterSetting = filterSettings[filter.filterIdentifier]
      if (!filter.values || filter?.values?.length === 0 || !filterSetting) return true

      if (filterSetting.type === 'rootKey') {
        if (!filterSetting.dataKey) return true
        return filter.values.includes(item[filterSetting.dataKey])
      }

      if (filterSetting.type === 'idInArray') {
        if (!filterSetting.dataKey) return true

        const itemDataArray: any[] = item[filterSetting.dataKey]
        const isArray = Array.isArray(itemDataArray)
        if (!isArray) return true
        return filter.values.some((value) => itemDataArray.includes(value))
      }
      if (filterSetting.type === 'keyInArray') {
        if (!filterSetting.dataKey || !filterSetting.dataPath) return true
        const itemDataArray: any[] = get(item, filterSetting.dataPath)
        const filtered = filter.values.some((value) =>
          itemDataArray.some((itemData) => itemData[filterSetting.dataKey] === value)
        )
        return filtered
      }
      if (filterSetting.type === 'fuelTypeGroup') {
        if (!filterSetting.dataKey) return true
        const eligbleFuelTypeIds = stationsStore.fuelTypes
          .filter((fuelType: FuelType) => {
            return filter.values.includes(fuelType.fuel_type_group)
          })
          .map((fuelType: FuelType) => fuelType.id)
        return item[filterSetting.dataKey].some((fuelTypeid: number) =>
          eligbleFuelTypeIds.includes(fuelTypeid)
        )
      }
      return true
    })
  }

  function updateFilterValues(filterIdentifier: string, values: (string | number)[]) {
    updateFilterValuesOnStore(filterIdentifier, values, stationsStore)
    updateFilterValuesOnStore(filterIdentifier, values, matchmakingStore)
    updateFilterValuesOnStore(filterIdentifier, values, statisticsStore)
  }

  function updateFiltersFromQueryParams() {
    const { query } = useRoute()
    if (query) {
      for (const key in query) {
        if (key !== 'focusExtent' && key !== 'layers') {
          const values = query[key] as string
          const toArray = values.split(',')
          const numberOrStringValues = toArray.map((value) =>
            isNaN(Number(value)) ? value : Number(value)
          )
          updateFilterValues(key, numberOrStringValues)
        }
      }
      if (query.layers) {
        const layerSettings = JSON.parse(query.layers as string)
        mapStore.layerVisibilityOveride = layerSettings
      }
    }
  }
  function getActiveFilters() {
    const activeFilters = [] as Filter[]
    ;[...stationsStore.filters, ...matchmakingStore.filters, ...statisticsStore.filters].forEach(
      (filter) => {
        if (!filter.values || filter.values.length === 0) return
        if (
          activeFilters.find(
            (activeFilter) => activeFilter.filterIdentifier === filter.filterIdentifier
          )
        )
          return
        activeFilters.push(filter)
      }
    )
    return activeFilters
  }
  function getActiveLayers() {
    const layerVisibilityParams = mapStore.activeLayers.reduce((acc, layer) => {
      acc[layer.identifier] = layer.visible
      return acc
    }, {} as { [key: string]: boolean })
    console.log(layerVisibilityParams)

    return layerVisibilityParams;
  }
  function updateUrlQueryParams() {
    const router = useRouter()
    const currentQuery = { ...router.currentRoute.value.query }
    const filters = getActiveFilters()
    filters.forEach((filter) => {
      if (filter.values.length === 0) {
        delete currentQuery[filter.filterIdentifier]
        return
      }
      currentQuery[filter.filterIdentifier] = filter.values.join(',')
    })

    if (mapStore.activeExtent) {
      currentQuery.focusExtent = mapStore.activeExtent.join(',')
    }
    const layerSettings = getActiveLayers()
    currentQuery.layers = JSON.stringify(layerSettings)
    router.push({ query: currentQuery })
  }

  return {
    applyFilterPreset,
    evalFiltersOnItem,
    updateFilterValues,
    updateFiltersFromQueryParams,
    updateUrlQueryParams,
  }
}

function updateFilterValuesOnStore(
  filterIdentifier: string,
  values: (string | number)[],
  store: any
) {
  const filterSetting = store.filterSettings[filterIdentifier]
  // console.log('updateFilterValuesOnStore', filterSetting, values)
  // console.trace()
  if (!filterSetting) return
  const newValues: (string | number)[] = values
  // if (filterSetting.type === 'idInArray') {
  //   newValues = values.map((value) => parseInt(value))
  //   console.log('updateFilterValuesOnStore', filterSetting, values, newValues)
  // }
  if (filterSetting.type === 'storeKey') {
    store[filterSetting.dataKey] = values[0]
  }
  const filter = store.filters.find(
    (filter: Filter) => filter.filterIdentifier === filterSetting.identifier
  )
  if (!filter) {
    store.filters.push({
      filterIdentifier: filterSetting.identifier,
      values: newValues,
    })
    return
  }
  filter.values = newValues
}
