import { acceptHMRUpdate, defineStore } from 'pinia'
import { Filter, FilterSettings } from '~/types/filters'
import { CachedStatistic } from '~/types/statistics'

const vehicleTypeOptions = [
  { label: 'Personbil', value: 'personbil', order: 1 },
  { label: 'Lätt lastbil', value: 'lätt lastbil', order: 2 },
  { label: 'Tung lastbil', value: 'tung lastbil', order: 3 },
]

const fuelTypeOptions = [
  { label: 'El', value: 'el', order: 1 },
  { label: 'Elhybrid', value: 'elhybrid', order: 2 },
  { label: 'Bensin', value: 'bensin', order: 3 },
  { label: 'Diesel', value: 'diesel', order: 4 },
  { label: 'Etanol', value: 'etanol', order: 5 },
  { label: 'Gas', value: 'gas', order: 6 },
  { label: 'Laddhybrid', value: 'laddhybrid', order: 7 },
  { label: 'Övriga', value: 'övriga', order: 8 },
]

export const statisticsFilterSettings: FilterSettings = {
  vehicleTypeFromFleetData: {
    identifier: 'vehicleTypeFromFleetData',
    dataKey: 'vehicle_type',
    dataPath: 'vehicle_data',
    label: 'Fordonstyp',
    type: 'keyInArray',
    selectType: 'multiple',
    options: vehicleTypeOptions,
  },
  fuelType: {
    identifier: 'fuelType',
    dataKey: 'fuel_type',
    dataPath: '',
    label: 'Drivmedel',
    type: 'keyInArray',
    selectType: 'single',
    options: fuelTypeOptions,
  },
}
const defaultFilters: Filter[] = [{ filterIdentifier: 'fuelType', values: ['el'] }]

export const useStatisticsStore = defineStore({
  id: 'statistics',

  state: () => ({
    filters: defaultFilters as Filter[],
    filterSettings: statisticsFilterSettings,
    cachedStatistics: {} as CachedStatistic<any>,
  }),
  getters: {
    getFiltervalues:
      ({ filters }) =>
      (identifier: string) => {
        return filters.find((filter) => filter.filterIdentifier === identifier)?.values || []
      },
  },
  actions: {},
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStatisticsStore, import.meta.hot))
}
