// formkit.config.ts
import type { DefaultConfigOptions } from '@formkit/vue'
import { primeInputs } from '@sfxcode/formkit-primevue'

const config: DefaultConfigOptions = {
  inputs: primeInputs,
  messages: {
    en: {
      validation: {
        required({ name }) {
          return `Vänligen fyll i ${name}.`
        },
      },
    },
  },
}

export default config
