export const colors = {
  red: '#D34536',
  yellow: '#ECD905',
  lightBlue: '#A1DEF0',
  darkBlue: '#074D65',
  lightGreen: '#69EF7B',
  green: '#2EA644',
  brown: '#753502',
  black: '#000000',
}

const symbolColors = {
  dustyGreen: '#7CD9A1',
  blue: '#527AF6',
  purple: '#AE71FF',
  pink: '#F5B2C9',
}

export const colorsForSymbols = {
  existing: symbolColors.dustyGreen,
  planned: symbolColors.blue,
  demand: symbolColors.pink,
  supply: symbolColors.purple,
}

export const colorsForHeatmap = {
  from: colors.darkBlue, // tidigare 074D65
  to: colors.yellow, // tidigareFFE663
}

export function useColors() {
  const colorArray = Object.values(colors)
  function mapKeysToColors(keys: string[]) {
    return keys.reduce((result, key, index) => {
      result[key] = colorArray[index]
      return result
    }, {} as Record<string, string>)
  }
  return { colors, colorArray, mapKeysToColors }
}
