import { defineStore } from 'pinia'
import { Definitions } from '@/types/types'

export const useDefinitionStore = defineStore({
  id: 'definitionsData',

  state: () => ({
    definitions: [] as Definitions[],
    fetchInProgress: false,
  }),
  actions: {
    async initDefinitions() {
      if (this.definitions.length > 0 || this.fetchInProgress) return
      this.fetchInProgress = true
      const { find } = useStrapi<Definitions>()
      const response = await find('definitions', {})

      this.definitions = response.data.map((definition) => {
        return {
          description: definition.attributes.description,
          title: definition.attributes.title,
          identifier: definition.attributes.identifier,
          group: definition.attributes.group,
        }
      })
      this.fetchInProgress = false
    },
  },
  getters: {
    getAllDefinitionsByGroup() {
      const groupedDefinitions = {} as any
      this.definitions.forEach((definition) => {
        const group = definition.group

        if (!groupedDefinitions[group]) {
          groupedDefinitions[group] = []
        }

        groupedDefinitions[group].push({
          ...definition,
        })
      })
      return groupedDefinitions
    },
    getDefinitionsGroup() {
      return (groupKey: string) => this.getAllDefinitionsByGroup[groupKey]
    }
  },
})
