<script setup>
useHead({
  title: 'Drivmedla',
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss">
@import 'App.scss';
</style>
