import { acceptHMRUpdate, defineStore } from 'pinia'

import { updateFilterValues } from './utils/filter-util'
import { stringSortOnPropertyPath } from './utils/data-utils'
import { StationResponse, Station, StationCategory, FuelType, FuelTypeGroup } from '~/types/station'
import { Filter, FilterSettingOption, FilterSettings } from '~/types/filters'
import { useGlobalFilters } from '~/composables/filters'
import { RegionType } from '~/types/types'

export const stationFilterSettings: FilterSettings = {
  electricityOrigin: {
    identifier: 'electricityOrigin',
    dataKey: 'exludeElectricityOrigin',
    label: 'Datakälla för befintliga laddstationer',
    description:
      'Drivmedla använder sig av två olika datakällor för laddstationer. Nobil och Uppladdning.nu. Välj vilken datakälla du vill använda.',
    type: 'storeKey',
    selectType: 'single',
    options: [
      { label: 'Uppladdning.nu', value: 'nobil', order: 1 },
      { label: 'Nobil', value: 'uppladdning', order: 2 },
    ],
  },
  stationType: {
    identifier: 'stationType',
    dataKey: 'type',
    label: 'Status',
    type: 'rootKey',
    selectType: 'multiple',
    options: [
      { label: 'Befintlig station', value: 'existing', order: 1 },
      { label: 'Planerad station', value: 'planned', order: 2 },
    ],
  },
  stationCategories: {
    identifier: 'stationCategories',
    dataKey: 'station_category_ids',
    label: 'Kapacitetskategori',
    type: 'idInArray',
    selectType: 'multiple',
    options: [],
  },
  fuelTypes: {
    identifier: 'fuelTypes',
    dataKey: 'fuel_type_ids',
    label: 'Stationer med drivmedel',
    type: 'idInArray',
    selectType: 'multiple',
    options: [],
  },
  gasFuelTypes: {
    identifier: 'gasFuelTypes',
    dataKey: 'fuel_type_ids',
    label: 'Stationer med gas',
    type: 'idInArray',
    selectType: 'multiple',
    options: [],
  },
  liquidFuelTypes: {
    identifier: 'liquidFuelTypes',
    dataKey: 'fuel_type_ids',
    label: 'Stationer med flytande bränsle',
    type: 'idInArray',
    selectType: 'multiple',
    options: [],
  },
  fuelTypeGroup: {
    identifier: 'fuelTypeGroup',
    dataKey: 'fuel_type_ids',
    label: 'Drivmedelstyp',
    type: 'fuelTypeGroup',
    selectType: 'multiple',
    options: [],
  },
}
const defaultExcludeElectricityOrigin = 'nobil'
const defaultFilters: Filter[] = [
  { filterIdentifier: 'electricityOrigin', values: [defaultExcludeElectricityOrigin] },
]

export const useStationDataStore = defineStore({
  id: 'stationData',
  state: () => ({
    stations: [] as Station[],
    stationsPending: false,
    stationCategories: [] as StationCategory[],
    fuelTypes: [] as FuelType[],
    fuelTypesPending: false,
    fuelTypeFilterOptions: [] as FilterSettingOption[],
    gasFuelFilterOptions: [] as FilterSettingOption[],
    liquidFuelFilterOptions: [] as FilterSettingOption[],
    fuelTypeGroupFilterOptions: [] as FilterSettingOption[],
    filters: defaultFilters as Filter[],
    filterSettings: stationFilterSettings,
    exludeElectricityOrigin: 'uppladdning' as 'nobil' | 'uppladdning',
  }),
  getters: {
    stationsWithSingleElectricityOrigin: ({ stations, exludeElectricityOrigin }) => {
      return stations.filter((station) => {
        return station.origin !== exludeElectricityOrigin
      })
    },
    stationsWithFilters({ filters, filterSettings }): Station[] {
      const stationsWithSingleelectricityOrigin = this
        ?.stationsWithSingleElectricityOrigin as Station[]
      const { evalFiltersOnItem } = useGlobalFilters()
      return stationsWithSingleelectricityOrigin.filter((station) => {
        return evalFiltersOnItem(station, filters, filterSettings)
      })
    },
    getFiltervalues:
      ({ filters }) =>
      (identifier: string) => {
        return filters.find((filter) => filter.filterIdentifier === identifier)?.values || []
      },
    getExistingStationCountGropedByFuelType({ stations, exludeElectricityOrigin, fuelTypes }) {
      const stationsWithSingleelectricityOrigin = this
        ?.stationsWithSingleElectricityOrigin as Station[]

      const gropedData = stationsWithSingleelectricityOrigin
        .filter(
          (station) => station.origin !== exludeElectricityOrigin && station.type === 'existing'
        )
        .reduce(groupStationsByFuelTypeReducer(fuelTypes), {})

      return Object.values(gropedData).sort(
        stringSortOnPropertyPath<{ fuelType: FuelType; stationCount: number }>(
          'fuelType.title',
          'asc'
        )
      )
    },
    getExistingStationCountGropedByFuelTypeByRegion({ fuelTypes, exludeElectricityOrigin }) {
      return (regionType?: RegionType, regionId?: string) => {
        const stations =
          regionType && regionId
            ? this.getStationsByRegion(regionType, regionId)
            : (this.stationsWithSingleElectricityOrigin as Station[])
        const gropedData = stations
          .filter(
            (station) => station.origin !== exludeElectricityOrigin && station.type === 'existing'
          )
          .reduce(groupStationsByFuelTypeReducer(fuelTypes), {})

        return Object.values(gropedData).sort(
          stringSortOnPropertyPath<{ fuelType: FuelType; stationCount: number }>(
            'fuelType.title',
            'asc'
          )
        )
      }
    },
    getFilteredStationsGropedByFuelType({ fuelTypes, filters }) {
      const stationsWithFilters = this?.stationsWithFilters as Station[]
      let groupedData = stationsWithFilters.reduce(groupStationsByFuelTypeReducer(fuelTypes), {})
      const fuelTypeGroupFilter = filters.find(
        (filter) => filter.filterIdentifier === 'fuelTypeGroup'
      )

      if (fuelTypeGroupFilter && fuelTypeGroupFilter.values.length > 0) {
        const reducedData: typeof groupedData = {}
        fuelTypeGroupFilter.values
          .reduce((acc: string[], fuelTypeGroupIdentifier) => {
            fuelTypes
              .filter((fuelType) => fuelType.fuel_type_group === fuelTypeGroupIdentifier)
              .forEach((fuelType) => {
                acc.push(fuelType.identifier)
              })
            return acc
          }, [])
          .forEach((fuelTypeIdentifier) => {
            if (groupedData[fuelTypeIdentifier])
              reducedData[fuelTypeIdentifier] = groupedData[fuelTypeIdentifier]
          })
        groupedData = reducedData
      }

      return Object.values(groupedData).sort(
        stringSortOnPropertyPath<{ fuelType: FuelType; stationCount: number }>(
          'fuelType.title',
          'asc'
        )
      )
    },
    getStationsGropedByFuelTypeGroup({ fuelTypes }) {
      const stations = this?.stationsWithSingleElectricityOrigin as Station[]
      return groupStationsByFuelTypeGroup(stations, fuelTypes)
    },
    getStationsGropedByFuelTypeGroupByRegion({ fuelTypes }) {
      return (regionType: RegionType, regionId: string) => {
        const stations = this?.getStationsByRegion(regionType, regionId) as Station[]
        return groupStationsByFuelTypeGroup(stations, fuelTypes)
      }
    },
    getStationsByRegion() {
      const stationsWithSingleelectricityOrigin = this
        ?.stationsWithSingleElectricityOrigin as Station[]

      return (regionType: RegionType, regionId: string) => {
        const idKeys: { [key: string]: string } = {
          county: 'county_id',
          municipality: 'municipality_id',
        }
        const regionIdKey = idKeys[regionType]
        const groupedData = stationsWithSingleelectricityOrigin.filter((station) => {
          return station[regionIdKey] === regionId
        })
        return Object.values(groupedData)
      }
    },
    getFilteredStationsGroupeByCategory({ stationCategories }) {
      const stationsWithFilters = this?.stationsWithFilters as Station[]
      console.log(stationsWithFilters.filter(d=>d.type !== 'existing' && d.type !== 'planned'))
      const gropedData = stationsWithFilters.reduce((acc, station) => {
        station.station_category_ids.forEach((stationCategoryId) => {
          const stationCategory = stationCategories.find(
            (stationCategory) => stationCategory.id === stationCategoryId
          )
          if (!stationCategory) return
          if (!acc[stationCategory.id]) {
            acc[stationCategory.id] = { category: stationCategory, stations: [] }
          }
          acc[stationCategory.id].stations.push(station)
        })
        return acc
      }, {} as { [key: string]: { category: StationCategory | null; stations: Station[] } })
      return Object.values(gropedData)
    },
    getStationsGroupedByType() {
      const stationsWithFilters = this?.stationsWithFilters as Station[]
      const gropedData = stationsWithFilters.reduce((acc, station) => {
        if (!acc[station.type]) {
          acc[station.type] = { type: station.type, stations: [], outletCount: 0 }
        }
        acc[station.type].stations.push(station)
        acc[station.type].outletCount += (station.total_outlets && station.total_outlets) || 0
        return acc
      }, {} as { [key: string]: { type: Station['type']; stations: Station[]; outletCount: number } })
      return gropedData
    },
    getFuelType() {
      return (fuelTypeId: number) => {
        return this.fuelTypes.find((fuelType) => fuelType.id === fuelTypeId)
      }
    },
    getElectrictyOrigin({ filters }) {
      const electricityOriginFilter = filters.find(
        (f) => f.filterIdentifier === 'electricityOrigin'
      )
      const exludeElectricityOrigin =
        electricityOriginFilter?.values[0] || defaultExcludeElectricityOrigin
      return exludeElectricityOrigin === 'nobil' ? 'uppladdning' : 'nobil'
    },
    getElectricPowerSumByMunicipality(): {
      [key: string]: { municipality_id: string; municipality_name: string; power: number }
    } | null {
      const uppladdningStations = this.stations.filter(
        (station) => station.origin === 'uppladdning'
      )
      const groupedData = uppladdningStations.reduce((acc, station) => {
        if (!station.municipality_id) return acc
        if (!acc[station.municipality_id]) {
          acc[station.municipality_id] = {
            municipality_id: station.municipality_id as string,
            municipality_name: station.municipality_name,
            power: 0,
          }
        }
        acc[station.municipality_id].power += station.total_power
        return acc
      }, {} as { [key: string]: { municipality_id: string; municipality_name: string; power: number } })
      if (Object.keys(groupedData).length === 0) return null
      return groupedData
    },
    getElectricPowerSumByCounty(): {
      [key: string]: { county_id: string; power: number }
    } | null {
      const uppladdningStations = this.stations.filter(
        (station) => station.origin === 'uppladdning'
      )
      const groupedData = uppladdningStations.reduce((acc, station) => {
        if (!station.county_id) return acc
        if (!acc[station.county_id]) {
          acc[station.county_id] = {
            county_id: station.county_id as string,
            power: 0,
          }
        }
        acc[station.county_id].power += station.total_power
        // console.log(acc[station.county_id].power)
        return acc
      }, {} as { [key: string]: { county_id: string; power: number } })
      if (Object.keys(groupedData).length === 0) return null
      return groupedData
    },
    getElectricPowerSumByCountry(): number | null {
      const uppladdningStations = this.stations.filter(
        (station) => station.origin === 'uppladdning'
      )
      const powerSum = uppladdningStations.reduce((acc, station) => {
        if (!station.total_power) return acc
        acc += station.total_power
        return acc
      }, 0)
      if (powerSum === 0) return null
      
      return powerSum
    },
  },
  actions: {
    async initStations() {
      if (this.stations.length > 0 || this.stationsPending) return
      this.stationsPending = true
      await this.populateFilterSettings()
      const { find } = useStrapi<StationResponse>()

      const response = await find('stations', {
        populate: undefined,
        fields: [
          'name',
          'origin',
          'fuel_type_ids',
          'station_category_ids',
          'origin_id',
          'lat',
          'lng',
          'type',
          'total_outlets',
          'total_power',
          'municipality_id',
          'county_id',
        ],
        pagination: { start: 0, limit: 20000 },
      })

      const fuelTypeGroupMap = this.fuelTypes.reduce((acc, fuelType) => {
        acc[fuelType.id] = fuelType.fuel_type_group
        return acc
      }, {} as { [key: number]: string })

      const data = response.data.map((StationResponse) => {
        let fuelTypeGroup: FuelTypeGroup = 'unknown'
        if (Array.isArray(StationResponse.attributes.fuel_type_ids)) {
          fuelTypeGroup = StationResponse.attributes.fuel_type_ids.reduce((acc, fuelTypeId) => {
            acc = acc === null ? fuelTypeGroupMap[fuelTypeId] : 'mixed'
            return acc
          }, null as string | null) as FuelTypeGroup
        }

        const station: Station = {
          ...StationResponse.attributes,
          id: StationResponse.id,
          fuelTypeGroup,
          hover: '0',
          selected: '0',
          // fuel_types: StationResponse.attributes.fuel_types.data.map((fuelType) => ({
          //   id: fuelType.id,
          //   ...fuelType.attributes,
          // })),
          // station_categories: StationResponse.attributes.station_categories.data.map(
          //   (stationCategory) => ({ id: stationCategory.id, ...stationCategory.attributes })
          // ),
        }
        return station
      })
      data.sort(stringSortOnPropertyPath<Station>('type', 'asc'))
      this.stationsPending = false
      this.stations = data as Station[]
      return data
    },
    async initFuelTypes() {
      if (this.fuelTypes.length > 0 || this.fuelTypesPending) return
      this.fuelTypesPending = true
      const { find } = useStrapi<FuelType>()
      const response = await find('fuel-types', {})
      const data = response.data.map((fuelTypeResponse) => {
        return {
          ...fuelTypeResponse.attributes,
          id: fuelTypeResponse.id,
        }
      })
      this.fuelTypes = data

      const fuelTypeOptions: FilterSettingOption[] = this.fuelTypes.map((fuelType) => {
        return {
          label: fuelType.title,
          value: fuelType.id,
        }
      })
      const fuelTypeGroupOptions: FilterSettingOption[] = this.fuelTypes
        .filter(
          (fuelType, i, a) =>
            a.findIndex((f) => f.fuel_type_group === fuelType.fuel_type_group) === i
        )
        .map((fuelType) => {
          return {
            label: fuelType.fuel_type_group,
            value: fuelType.fuel_type_group,
          }
        })

      const groupedFuelTypes = this.fuelTypes.reduce((acc, fuelType) => {
        if (!acc[fuelType.fuel_type_group]) acc[fuelType.fuel_type_group] = []
        acc[fuelType.fuel_type_group].push({
          label: fuelType.title,
          value: fuelType.id,
        })
        return acc
      }, {} as { [key: string]: FilterSettingOption[] })

      this.fuelTypeFilterOptions = fuelTypeOptions
      this.fuelTypeGroupFilterOptions = Object.keys(groupedFuelTypes).map((group) => ({
        label: group,
        value: group,
      }))
      this.gasFuelFilterOptions = groupedFuelTypes.gas || []
      this.liquidFuelFilterOptions = groupedFuelTypes.liquid || []

      this.filterSettings.fuelTypes.options = fuelTypeOptions
      this.filterSettings.gasFuelTypes.options = this.gasFuelFilterOptions
      this.filterSettings.liquidFuelTypes.options = this.liquidFuelFilterOptions
      this.filterSettings.fuelTypeGroup.options = fuelTypeGroupOptions
      this.fuelTypesPending = false
      return data
    },
    async populateFilterSettings() {
      const { find } = useStrapi<StationCategory>()
      const response = await find('station-categories', {})

      const stationCategories: StationCategory[] = response.data.map((stationCategoryResponse) => {
        return {
          ...stationCategoryResponse.attributes,
          id: stationCategoryResponse.id,
        }
      })

      const categoryOptions: FilterSettingOption[] = stationCategories.map((stationCategory) => {
        return {
          label: stationCategory.title,
          value: stationCategory.id,
          order: stationCategory.order,
        }
      })
      this.stationCategories = stationCategories
      this.filterSettings.stationCategories.options = categoryOptions.sort(
        (a, b) => (a.order || 0) - (b.order || 0)
      )

      await this.initFuelTypes()
    },
    updateFilter(filterSettingIdentifier: string, values: string[]) {
      updateFilterValues(filterSettingIdentifier, values, this)
    },
    setDefaultExludeElectricityOrigin() {
      this.updateFilter('electricityOrigin', [defaultExcludeElectricityOrigin])
    },
    resetFilters() {
      this.filters = []
    },
  },
})

function groupStationsByFuelTypeGroup(stations: Station[], fuelTypes: FuelType[]) {
  const groupedData = stations.reduce(groupStationsByFuelTypeReducer(fuelTypes), {})
  const emptyGroups = {
    electricity: {
      fuelTypeGroup: 'electricity',
      stationCount: 0,
      fuelTypes: [],
    },
    gas: {
      fuelTypeGroup: 'gas',
      stationCount: 0,
      fuelTypes: [],
    },
    liquid: {
      fuelTypeGroup: 'liquid',
      stationCount: 0,
      fuelTypes: [],
    },
  }
  const groupedByFuelTypeGroup = Object.values(groupedData).reduce((acc, item) => {
    if (!acc[item.fuelType.fuel_type_group])
      acc[item.fuelType.fuel_type_group] = {
        fuelTypeGroup: item.fuelType.fuel_type_group,
        stationCount: 0,
        fuelTypes: [],
      }
    acc[item.fuelType.fuel_type_group].stationCount += item.stationCount
    acc[item.fuelType.fuel_type_group].fuelTypes.push(item)
    return acc
  }, emptyGroups as { [key: string]: { fuelTypeGroup: string; stationCount: number; fuelTypes: any[] } })
  return Object.values(groupedByFuelTypeGroup)
}

function groupStationsByFuelTypeReducer(fuelTypes: FuelType[]) {
  return (
    acc: { [key: string]: { fuelType: FuelType; stationCount: number } },
    station: Station
  ) => {
    if (station.type === 'planned') return acc
    station.fuel_type_ids.forEach((fuelTypeId) => {
      const fuelType = fuelTypes.find((fuelType) => fuelType.id === fuelTypeId)
      if (!fuelType) return

      if (!acc[fuelType.identifier]) {
        acc[fuelType.identifier] = { fuelType, stationCount: 0 }
      }
      acc[fuelType.identifier].stationCount++
    })
    return acc
  }
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStationDataStore, import.meta.hot))
}
