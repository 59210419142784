import { acceptHMRUpdate, defineStore } from 'pinia'
import { County, ElectricityGridOwner, RegionPages, Municipality } from '@/types/types'

export const useGeoDataStore = defineStore({
  id: 'geoData',

  state: () => ({
    appVersion: useRuntimeConfig().public.APP_VERSION,
    electricityGridOwners: [] as ElectricityGridOwner[],
    municipalities: [] as Municipality[],
    counties: [] as County[],
    lantmaterietToken: '',
    lantmaterietAuth: '',
    lantmaterietExpiresIn: 0,
    lantmaterietTokenTimestamp: 0,
    pageCounty: {} as RegionPages,
    pageMunicipality: {} as RegionPages,
    pending: {
      municipalities: false,
      counties: false,
      electricityGridOwners: false,
    },
  }),
  actions: {
    async getToken() {
      // const currentTimestamp = Math.floor(Date.now() / 1000)
      if (!this.lantmaterietAuth) {
        // Token has expired or doesn't exist
        const client = useStrapiClient()
        const data = await client<{ expiry: number; accessToken: string; auth: string }>(
          '/map-token'
        ).then((response) => response)
        this.lantmaterietAuth = data.auth
      }
    },
    async initPageCounty() {
      if (Object.keys(this.pageCounty).length > 0) return
      const { findOne } = useStrapi<RegionPages>()
      const data = await findOne('sida-region')
      this.pageCounty = data.data.attributes as RegionPages
      return data.data.attributes
    },
    async initPageMunicipality() {
      if (Object.keys(this.pageMunicipality).length > 0) return
      const { findOne } = useStrapi<RegionPages>()
      const data = await findOne('sida-kommun')
      this.pageMunicipality = data.data.attributes as RegionPages
      return data.data.attributes
    },
    async initMunicipalities() {
      if (this.municipalities.length > 0 || this.pending.municipalities) return
      this.pending.municipalities = true
      const { find } = useStrapi<Municipality>()
      const data = await find('municipalities', { pagination: { start: 0, limit: 10000 } }).then(
        (response) =>
          response.data.map((data) => {
            return data.attributes
          })
      )
      this.pending.municipalities = false
      this.municipalities = data as Municipality[]
      return data
    },
    async initCounties() {
      if (this.counties.length > 0 || this.pending.counties) return this.counties
      this.pending.counties = true
      const { find } = useStrapi<County>()
      const data = await find('counties', { pagination: { start: 0, limit: 10000 } }).then(
        (response) =>
          response.data
            .map((data, i) => {
              return data.attributes
            })
            .sort((a, b) => a.name.localeCompare(b.name))
      )
      this.pending.counties = false
      this.counties = data as County[]
      return data
    },
    async initElectricityGridOwners() {
      if (this.electricityGridOwners.length > 0 || this.pending.electricityGridOwners) return
      this.pending.electricityGridOwners = true
      const { find } = useStrapi<ElectricityGridOwner>()
      const data = await find('electricity-network-owners', {
        pagination: { start: 0, limit: 10000 },
      }).then((response) =>
        response.data.map((data, i) => {
          return data.attributes
        })
      )
      this.pending.electricityGridOwners = false
      this.electricityGridOwners = data as ElectricityGridOwner[]
      return data
    },
  },
  getters: {
    getCountyByCountyId: (state) => {
      return (code?: string) => state.counties.find((county) => county.county_code === code)
    },
    getMuniciplityByMunicipalityId: (state) => {
      return (code?: string) =>
        state.municipalities.find((municipality) => municipality.municipality_code === code)
    },
  },
})

// function getGeoData(contentType: GeoDataType, find) {
//   return find(contentType, { pagination: { start: 0, limit: 10000 } }).then((response) =>
//     response.data.map((data, i) => {
//       return data.attributes
//     })
//   )
// }
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGeoDataStore, import.meta.hot))
}
