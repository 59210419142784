const operator = [
  {
    $formkit: 'primeDropdown',
    id: 'operator',
    name: 'operator',
    bind: '$operator',
    label: 'Välj vad du tycker bäst passar in på dig/din verksamhet.',
    labelClass: 'required-field',
    placeholder: 'Välj',
    options: '$operatorOptions',
    validation: 'required|string',
  },
]
const type = [
  {
    $formkit: 'primeDropdown',
    id: 'type',
    name: 'type',
    bind: '$type',
    label: 'Vad önskar ni?',
    options: '$typeOptions',
  },
]
const offer = [
  {
    $formkit: 'primeDropdown',
    id: 'offer',
    name: 'offer',
    bind: '$offer',
    label: 'Vad erbjuder/söker ni?',
    options: '$offerOptions',
  },
]
const offerPublicActor = [
  {
    $formkit: 'primeDropdown',
    id: 'offer_public_actor',
    name: 'offer_public_actor',
    bind: '$offer_public_actor',
    label: 'Vad önskar ni?',
    options: '$offerPublicActorOptions',
  },
]
const requirement = [
  {
    $formkit: 'primeDropdown',
    id: 'requirement',
    name: 'requirement',
    bind: '$requirement',
    label: 'Vad önskar ni?',
    options: '$requirementOptions',
  },
]
const fuelType = [
  {
    $formkit: 'primeMultiSelect',
    bind: '$fuel_type',
    name: 'fuel_type',
    label: 'Drivmedel',
    help: 'Det går att välja flera alternativ',
    placeholder: 'Välj drivmedel',
    options: '$fuelTypeOptions',
  },
]
const fuelTypeVolumes = [
  {
    $formkit: 'primeInputText',
    bind: '$fuel_type_volumes',
    name: 'fuel_type_volumes',
    label: 'Volymer/energimängd som behövs',
    help: 'liter / m3 / ton / MWh / kW',
    placeholder: 'ex. El: 350 kW på 4 laddpunkter',
  },
]
const accessibility = [
  {
    $formkit: 'primeDropdown',
    bind: '$accessibility',
    name: 'accessibility',
    label: 'Tillgänglighet',
    placeholder: 'Välj tillgänglighet',
    help: 'Semi-publik är tillgänglig en viss tid under dygnet',
    options: '$accessibilityOptions',
  },
]
const vehicleType = {
  $formkit: 'primeMultiSelect',
  bind: '$vehicle_type',
  name: 'vehicle_type',
  label: 'Fordonstyp',
  placeholder: 'Välj de som gäller',
  options: '$vehicleTypeOptions',
}
const vehicleTypeAmount = {
  $formkit: 'primeInputText',
  bind: '$vehicle_type_amount',
  name: 'vehicle_type_amount',
  label: 'Antal fordon',
  placeholder: 'ex. Biogas: 10-15 lätta lastbilar, vätgas: 5-10 tunga lastbilar',
  help: 'Antal fordon (tunga respektive lätta) per drivmedelstyp',
}
const vehicle = [
  {
    $el: 'div',
    attrs: {
      class: 'flex row flex-wrap',
    },
    children: [vehicleType, vehicleTypeAmount],
  },
]
const refill = [
  {
    $formkit: 'primeDropdown',
    bind: '$refill',
    name: 'refill',
    label: 'Påfyllning',
    placeholder: 'Välj',
    options: '$refillOptions',
  },
]
const capacityType = {
  $formkit: 'primeMultiSelect',
  bind: '$capacity',
  name: 'capacity',
  label: 'Laddeffekt',
  placeholder: 'Vilken laddeffekt kan ni leverera till kund?',
  options: '$capacityOptions',
}
const capacityAmount = {
  $formkit: 'primeInputText',
  bind: '$capacity_amount',
  name: 'capacity_amount',
  label: 'Antal laddpunkter',
  placeholder:
    'Fyll i antal laddpunkter som totalt kan levereras för respektive laddeffekt enligt ovan, exempelvis 4 Depå, 2 Snabbladdning.',
}
const capacity = [
  {
    $el: 'div',
    attrs: {
      class: 'flex row flex-wrap',
    },
    children: [capacityType, capacityAmount],
  },
]
const startDate = {
  $formkit: 'primeCalendar',
  label: 'När startar behovet/erbjudandet?',
  bind: '$start_date',
  name: 'start_date',
  help: 'Från och med',
  labelClass: 'required-field',
  validation: 'required|string',
  dateFormat: 'dd/mm/yy',
  validationMessages: {
    required: 'Vänligen fyll i ett startdatum',
  },
}
const startDatePublicActor = {
  ...startDate,
  label: 'Från när gäller indikeringen?',
}
const startDateLandOwner = {
  ...startDate,
  label: 'När startar erbjudandet/behovet?',
}
const today = new Date()
const endDate = {
  $formkit: 'primeCalendar',
  label: 'Hur länge gäller det?',
  bind: '$end_date',
  name: 'end_date',
  help: 'Slutdatum sätts automatiskt till 12 månader efter startdatumet om inget annat anges.',
  dateFormat: 'dd/mm/yy',
  validation: `date_after:${today}`,
  labelClass: 'required-field',
  validationMessages: {
    date_after: 'Slutdatum måste vara efter startdatumet',
  },
}
const date = {
  $el: 'div',
  attrs: {
    class: 'flex row flex-wrap',
  },
  children: [startDate, endDate],
}
const lat = {
  $formkit: 'primeInputNumber',
  bind: '$lat',
  name: 'lat',
  minFractionDigits: 0,
  maxFractionDigits: 7,
  label: 'Latitude',
  labelClass: 'required-field',
  validation: 'required|number',
}
const lng = {
  $formkit: 'primeInputNumber',
  bind: '$lng',
  name: 'lng',
  minFractionDigits: 0,
  maxFractionDigits: 7,
  label: 'Longitude',
  labelClass: 'required-field',
  validation: 'required|number',
}
const position = {
  $el: 'div',
  attrs: {
    class: 'flex row flex-wrap',
  },
  children: [lat, lng],
}
const county = {
  $formkit: 'primeDropdown',
  bind: '$county',
  name: 'county',
  label: 'Län',
  labelClass: 'required-field',
  placeholder: 'Välj län',
  options: '$counties',
  validation: 'required|string',
}
const addressDescription = {
  $formkit: 'primeTextarea',
  bind: '$address_description',
  name: 'address_description',
  label: 'Kommentar',
  placeholder: 'Här kan du lägga till ytterligare information om platsen',
}
const address = {
  $el: 'div',
  attrs: {
    class: 'flex row flex-wrap',
  },
  children: [county, addressDescription],
}

const location = [
  {
    $el: 'div',
    attrs: {
      class: 'flex row flex-wrap',
    },
    children: [date, address],
  },
]

const locationPublicActor = [
  {
    $el: 'div',
    attrs: {
      class: 'flex row flex-wrap',
    },
    children: [startDatePublicActor, endDate, address],
  },
]

const locationLandOwner = [
  {
    $el: 'div',
    attrs: {
      class: 'flex row flex-wrap',
    },
    children: [startDateLandOwner, endDate, address],
  },
]

const operatorName = {
  $formkit: 'primeInputText',
  bind: '$operator_name',
  name: 'operator_name',
  label: 'Namn/verksamhetsnamn',
  labelClass: 'required-field',
  validation: 'required|string',
  validationMessages: {
    accepted: 'Vänligen fyll i ett namn på din organsation/verksamhet.',
  },
  placeholder: 'Namn på din organsation/verksamhet',
}
const organisationnumber = {
  $formkit: 'primeInputText',
  bind: '$organisationnumber',
  name: 'organisationnumber',
  label: 'Org.nummer',
  placeholder: '000000-0000',
}
const operatorDescription = {
  $formkit: 'primeTextarea',
  bind: '$operator_description',
  name: 'operator_description',
  label: 'Introduktion',
  placeholder: 'Skriv gärna en kort introduktion till din organisation.',
}
const webpage = {
  $formkit: 'primeInputText',
  bind: '$webpage',
  name: 'webpage',
  label: 'Hemsida',
  placeholder: 'www.hemsida.se',
}
const organisation = {
  $el: 'div',
  attrs: {
    class: 'flex row flex-wrap',
  },
  children: [operatorName, organisationnumber, operatorDescription, webpage],
}
const firstname = {
  $formkit: 'primeInputText',
  bind: '$firstname',
  name: 'firstname',
  label: 'Förnamn',
  placeholder: 'Förnamn till kontaktperson',
}
const lastname = {
  $formkit: 'primeInputText',
  bind: '$lastname',
  name: 'lastname',
  label: 'Efternamn',
  placeholder: 'Efternamn till kontaktperson',
}
const phone = {
  $formkit: 'primeInputText',
  bind: '$phone',
  name: 'phone',
  label: 'Telefonnummer',
  placeholder: '010-010 010',
}
const mail = {
  $formkit: 'primeInputText',
  bind: '$mail',
  name: 'mail',
  label: 'Epostadress',
  labelClass: 'required-field',
  help: 'Detta kommer att användas för att bekräfta och redigera din annons.',
  validation: 'required|email',
  placeholder: 'namn.efternamn@mail.se',
}
const contactDetails = {
  $el: 'div',
  attrs: {
    class: 'flex row flex-wrap',
  },
  children: [firstname, lastname, phone, mail],
}
const contact = [
  {
    $el: 'div',
    attrs: {
      class: 'flex row flex-wrap',
    },
    children: [organisation, contactDetails],
  },
]
const terms = [
  {
    $formkit: 'checkbox',
    bind: '$terms',
    name: 'terms',
    label: 'Jag accepterar villkoren för att skapa ett inlägg',
    validation: 'accepted',
    validationMessages: {
      accepted: 'Vänligen acceptera villkoren för att skapa ett inlägg.',
    },
    outerClass: 'terms-checkbox',
  },
]

export const question = {
  operator,
  type,
  offer,
  offerPublicActor,
  requirement,
  fuelType,
  fuelTypeVolumes,
  accessibility,
  vehicle,
  refill,
  capacity,
  location,
  locationPublicActor,
  locationLandOwner,
  contact,
  terms,
}
