import { useStationDataStore } from '../stations'
import { Filter, FilterSetting, FilterSettings } from '~/types/filters'
import { FuelType, Station } from '~/types/station'
import { MatchmakingItem } from '~/types/types'

export function evalFiltersOnItem(
  item: MatchmakingItem | Station | any,
  filters: Filter[],
  filterSettings: FilterSettings,
  store: any
) {
  console.log('evalFiltersOnItem', item, filters, filterSettings)
  return filters.every((filter) => {
    const filterSetting = filterSettings[filter.filterIdentifier]
    if (filter.values.length === 0 || !filterSetting) return true

    if (filterSetting.type === 'rootKey') {
      if (!filterSetting.dataKey) return true
      return filter.values.includes(item[filterSetting.dataKey])
    }

    if (filterSetting.type === 'idInArray') {
      if (!filterSetting.dataKey) return true

      const itemDataArray: any[] = item[filterSetting.dataKey]
      const isArray = Array.isArray(itemDataArray)
      if (!isArray) return true
      return filter.values.some((value) => itemDataArray.includes(value))
    }
    if (filterSetting.type === 'fuelTypeGroup') {
      if (!filterSetting.dataKey) return true
      const eligbleFuelTypeIds = store.fuelTypes
        .filter((fuelType: FuelType) => {
          return filter.values.includes(fuelType.fuel_type_group)
        })
        .map((fuelType: FuelType) => fuelType.id)
      return item[filterSetting.dataKey].some((fuelTypeid: number) =>
        eligbleFuelTypeIds.includes(fuelTypeid)
      )
    }
    return true
  })
}

export function updateFilterValues(filterIdentifier: string, values: string[], store: any) {
  const filterSetting = store.filterSettings[filterIdentifier]
  if (!filterSetting) return

  if (filterSetting.type === 'storeKey') {
    store[filterSetting.dataKey] = values[0]
  }
  const filter = store.filters.find(
    (filter: Filter) => filter.filterIdentifier === filterSetting.identifier
  )
  if (!filter) {
    store.filters.push({
      filterIdentifier: filterSetting.identifier,
      values,
    })
    return
  }
  filter.values = values
}

export function createSlug(value: string | number) {
  return value
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
}
