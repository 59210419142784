import { default as _404XXgWY7HqtvMeta } from "/github/workspace/frontend/pages/404.vue?macro=true";
import { default as _91slug_937BqoU7uUAkMeta } from "/github/workspace/frontend/pages/[slug].vue?macro=true";
import { default as definitionerH8JxXPHSudMeta } from "/github/workspace/frontend/pages/definitioner.vue?macro=true";
import { default as fordonsinnehavarexL4kT87yoaeMeta } from "/github/workspace/frontend/pages/fordonsinnehavarex.vue?macro=true";
import { default as indexl3TX3edQ6lMeta } from "/github/workspace/frontend/pages/index.vue?macro=true";
import { default as installningar_45kakorhFTIZiSgYFMeta } from "/github/workspace/frontend/pages/installningar-kakor.vue?macro=true";
import { default as integritetspolicyHqJPQHbOF3Meta } from "/github/workspace/frontend/pages/integritetspolicy.vue?macro=true";
import { default as _91slug_93ikaXESJNbhMeta } from "/github/workspace/frontend/pages/kartor-och-statistik/[slug].vue?macro=true";
import { default as indexH19Ry8qobyMeta } from "/github/workspace/frontend/pages/kartor-och-statistik/index.vue?macro=true";
import { default as _91slug_934AOPP9ZaTMMeta } from "/github/workspace/frontend/pages/kunskapsbank/[slug].vue?macro=true";
import { default as indexEPtulRJriwMeta } from "/github/workspace/frontend/pages/kunskapsbank/index.vue?macro=true";
import { default as om_45drivmedlaBgg7RF35pvMeta } from "/github/workspace/frontend/pages/om-drivmedla.vue?macro=true";
import { default as _91slug_93SWackVOQtqMeta } from "/github/workspace/frontend/pages/samarbeta/[slug].vue?macro=true";
import { default as indexjeBFpOqd2cMeta } from "/github/workspace/frontend/pages/samarbeta/admin/[slug]/[adminSlug]/index.vue?macro=true";
import { default as indexuYwzeWLJOrMeta } from "/github/workspace/frontend/pages/samarbeta/index.vue?macro=true";
import { default as skapa_45inlaggNmudYK3vvxMeta } from "/github/workspace/frontend/pages/samarbeta/skapa-inlagg.vue?macro=true";
import { default as samtycke_45kakorgoRfgaI9o1Meta } from "/github/workspace/frontend/pages/samtycke-kakor.vue?macro=true";
import { default as serverv6xnn8N1KrMeta } from "/github/workspace/frontend/pages/server.vue?macro=true";
import { default as indexajhrx5TxGcMeta } from "/github/workspace/frontend/pages/skapa-karta/index.vue?macro=true";
import { default as storesOfSDviuLTrMeta } from "/github/workspace/frontend/pages/stores.vue?macro=true";
import { default as indexxTDytwT7tCMeta } from "/github/workspace/frontend/pages/utforska-en-plats/index.vue?macro=true";
import { default as _91slug_931PDeowJCmcMeta } from "/github/workspace/frontend/pages/utforska-en-plats/kommun/[slug].vue?macro=true";
import { default as _91slug_93Bzhq5AVhHeMeta } from "/github/workspace/frontend/pages/utforska-en-plats/region/[slug].vue?macro=true";
import { default as indexKIQYuibk2rMeta } from "/github/workspace/frontend/pages/utforska-en-plats/sverige/index.vue?macro=true";
import { default as villkorcxH7zuBtB6Meta } from "/github/workspace/frontend/pages/villkor.vue?macro=true";
export default [
  {
    name: _404XXgWY7HqtvMeta?.name ?? "404",
    path: _404XXgWY7HqtvMeta?.path ?? "/404",
    meta: _404XXgWY7HqtvMeta || {},
    alias: _404XXgWY7HqtvMeta?.alias || [],
    redirect: _404XXgWY7HqtvMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91slug_937BqoU7uUAkMeta?.name ?? "slug",
    path: _91slug_937BqoU7uUAkMeta?.path ?? "/:slug()",
    meta: _91slug_937BqoU7uUAkMeta || {},
    alias: _91slug_937BqoU7uUAkMeta?.alias || [],
    redirect: _91slug_937BqoU7uUAkMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: definitionerH8JxXPHSudMeta?.name ?? "definitioner",
    path: definitionerH8JxXPHSudMeta?.path ?? "/definitioner",
    meta: definitionerH8JxXPHSudMeta || {},
    alias: definitionerH8JxXPHSudMeta?.alias || [],
    redirect: definitionerH8JxXPHSudMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/definitioner.vue").then(m => m.default || m)
  },
  {
    name: fordonsinnehavarexL4kT87yoaeMeta?.name ?? "fordonsinnehavarex",
    path: fordonsinnehavarexL4kT87yoaeMeta?.path ?? "/fordonsinnehavarex",
    meta: fordonsinnehavarexL4kT87yoaeMeta || {},
    alias: fordonsinnehavarexL4kT87yoaeMeta?.alias || [],
    redirect: fordonsinnehavarexL4kT87yoaeMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/fordonsinnehavarex.vue").then(m => m.default || m)
  },
  {
    name: indexl3TX3edQ6lMeta?.name ?? "index",
    path: indexl3TX3edQ6lMeta?.path ?? "/",
    meta: indexl3TX3edQ6lMeta || {},
    alias: indexl3TX3edQ6lMeta?.alias || [],
    redirect: indexl3TX3edQ6lMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: installningar_45kakorhFTIZiSgYFMeta?.name ?? "installningar-kakor",
    path: installningar_45kakorhFTIZiSgYFMeta?.path ?? "/installningar-kakor",
    meta: installningar_45kakorhFTIZiSgYFMeta || {},
    alias: installningar_45kakorhFTIZiSgYFMeta?.alias || [],
    redirect: installningar_45kakorhFTIZiSgYFMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/installningar-kakor.vue").then(m => m.default || m)
  },
  {
    name: integritetspolicyHqJPQHbOF3Meta?.name ?? "integritetspolicy",
    path: integritetspolicyHqJPQHbOF3Meta?.path ?? "/integritetspolicy",
    meta: integritetspolicyHqJPQHbOF3Meta || {},
    alias: integritetspolicyHqJPQHbOF3Meta?.alias || [],
    redirect: integritetspolicyHqJPQHbOF3Meta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/integritetspolicy.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ikaXESJNbhMeta?.name ?? "kartor-och-statistik-slug",
    path: _91slug_93ikaXESJNbhMeta?.path ?? "/kartor-och-statistik/:slug()",
    meta: _91slug_93ikaXESJNbhMeta || {},
    alias: _91slug_93ikaXESJNbhMeta?.alias || [],
    redirect: _91slug_93ikaXESJNbhMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/kartor-och-statistik/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexH19Ry8qobyMeta?.name ?? "kartor-och-statistik",
    path: indexH19Ry8qobyMeta?.path ?? "/kartor-och-statistik",
    meta: indexH19Ry8qobyMeta || {},
    alias: indexH19Ry8qobyMeta?.alias || [],
    redirect: indexH19Ry8qobyMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/kartor-och-statistik/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_934AOPP9ZaTMMeta?.name ?? "kunskapsbank-slug",
    path: _91slug_934AOPP9ZaTMMeta?.path ?? "/kunskapsbank/:slug()",
    meta: _91slug_934AOPP9ZaTMMeta || {},
    alias: _91slug_934AOPP9ZaTMMeta?.alias || [],
    redirect: _91slug_934AOPP9ZaTMMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/kunskapsbank/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexEPtulRJriwMeta?.name ?? "kunskapsbank",
    path: indexEPtulRJriwMeta?.path ?? "/kunskapsbank",
    meta: indexEPtulRJriwMeta || {},
    alias: indexEPtulRJriwMeta?.alias || [],
    redirect: indexEPtulRJriwMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/kunskapsbank/index.vue").then(m => m.default || m)
  },
  {
    name: om_45drivmedlaBgg7RF35pvMeta?.name ?? "om-drivmedla",
    path: om_45drivmedlaBgg7RF35pvMeta?.path ?? "/om-drivmedla",
    meta: om_45drivmedlaBgg7RF35pvMeta || {},
    alias: om_45drivmedlaBgg7RF35pvMeta?.alias || [],
    redirect: om_45drivmedlaBgg7RF35pvMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/om-drivmedla.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SWackVOQtqMeta?.name ?? "samarbeta-slug",
    path: _91slug_93SWackVOQtqMeta?.path ?? "/samarbeta/:slug()",
    meta: _91slug_93SWackVOQtqMeta || {},
    alias: _91slug_93SWackVOQtqMeta?.alias || [],
    redirect: _91slug_93SWackVOQtqMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/samarbeta/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexjeBFpOqd2cMeta?.name ?? "samarbeta-admin-slug-adminSlug",
    path: indexjeBFpOqd2cMeta?.path ?? "/samarbeta/admin/:slug()/:adminSlug()",
    meta: indexjeBFpOqd2cMeta || {},
    alias: indexjeBFpOqd2cMeta?.alias || [],
    redirect: indexjeBFpOqd2cMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/samarbeta/admin/[slug]/[adminSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexuYwzeWLJOrMeta?.name ?? "samarbeta",
    path: indexuYwzeWLJOrMeta?.path ?? "/samarbeta",
    meta: indexuYwzeWLJOrMeta || {},
    alias: indexuYwzeWLJOrMeta?.alias || [],
    redirect: indexuYwzeWLJOrMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/samarbeta/index.vue").then(m => m.default || m)
  },
  {
    name: skapa_45inlaggNmudYK3vvxMeta?.name ?? "samarbeta-skapa-inlagg",
    path: skapa_45inlaggNmudYK3vvxMeta?.path ?? "/samarbeta/skapa-inlagg",
    meta: skapa_45inlaggNmudYK3vvxMeta || {},
    alias: skapa_45inlaggNmudYK3vvxMeta?.alias || [],
    redirect: skapa_45inlaggNmudYK3vvxMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/samarbeta/skapa-inlagg.vue").then(m => m.default || m)
  },
  {
    name: samtycke_45kakorgoRfgaI9o1Meta?.name ?? "samtycke-kakor",
    path: samtycke_45kakorgoRfgaI9o1Meta?.path ?? "/samtycke-kakor",
    meta: samtycke_45kakorgoRfgaI9o1Meta || {},
    alias: samtycke_45kakorgoRfgaI9o1Meta?.alias || [],
    redirect: samtycke_45kakorgoRfgaI9o1Meta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/samtycke-kakor.vue").then(m => m.default || m)
  },
  {
    name: serverv6xnn8N1KrMeta?.name ?? "server",
    path: serverv6xnn8N1KrMeta?.path ?? "/server",
    meta: serverv6xnn8N1KrMeta || {},
    alias: serverv6xnn8N1KrMeta?.alias || [],
    redirect: serverv6xnn8N1KrMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/server.vue").then(m => m.default || m)
  },
  {
    name: indexajhrx5TxGcMeta?.name ?? "skapa-karta",
    path: indexajhrx5TxGcMeta?.path ?? "/skapa-karta",
    meta: indexajhrx5TxGcMeta || {},
    alias: indexajhrx5TxGcMeta?.alias || [],
    redirect: indexajhrx5TxGcMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/skapa-karta/index.vue").then(m => m.default || m)
  },
  {
    name: storesOfSDviuLTrMeta?.name ?? "stores",
    path: storesOfSDviuLTrMeta?.path ?? "/stores",
    meta: storesOfSDviuLTrMeta || {},
    alias: storesOfSDviuLTrMeta?.alias || [],
    redirect: storesOfSDviuLTrMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/stores.vue").then(m => m.default || m)
  },
  {
    name: indexxTDytwT7tCMeta?.name ?? "utforska-en-plats",
    path: indexxTDytwT7tCMeta?.path ?? "/utforska-en-plats",
    meta: indexxTDytwT7tCMeta || {},
    alias: indexxTDytwT7tCMeta?.alias || [],
    redirect: indexxTDytwT7tCMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/utforska-en-plats/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_931PDeowJCmcMeta?.name ?? "utforska-en-plats-kommun-slug",
    path: _91slug_931PDeowJCmcMeta?.path ?? "/utforska-en-plats/kommun/:slug()",
    meta: _91slug_931PDeowJCmcMeta || {},
    alias: _91slug_931PDeowJCmcMeta?.alias || [],
    redirect: _91slug_931PDeowJCmcMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/utforska-en-plats/kommun/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Bzhq5AVhHeMeta?.name ?? "utforska-en-plats-region-slug",
    path: _91slug_93Bzhq5AVhHeMeta?.path ?? "/utforska-en-plats/region/:slug()",
    meta: _91slug_93Bzhq5AVhHeMeta || {},
    alias: _91slug_93Bzhq5AVhHeMeta?.alias || [],
    redirect: _91slug_93Bzhq5AVhHeMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/utforska-en-plats/region/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexKIQYuibk2rMeta?.name ?? "utforska-en-plats-sverige",
    path: indexKIQYuibk2rMeta?.path ?? "/utforska-en-plats/sverige",
    meta: indexKIQYuibk2rMeta || {},
    alias: indexKIQYuibk2rMeta?.alias || [],
    redirect: indexKIQYuibk2rMeta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/utforska-en-plats/sverige/index.vue").then(m => m.default || m)
  },
  {
    name: villkorcxH7zuBtB6Meta?.name ?? "villkor",
    path: villkorcxH7zuBtB6Meta?.path ?? "/villkor",
    meta: villkorcxH7zuBtB6Meta || {},
    alias: villkorcxH7zuBtB6Meta?.alias || [],
    redirect: villkorcxH7zuBtB6Meta?.redirect || undefined,
    component: () => import("/github/workspace/frontend/pages/villkor.vue").then(m => m.default || m)
  }
]