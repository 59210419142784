import { useStationDataStore } from '~/stores/stations'

const operator = {
  vehicle_owner: 'Fordonsägare/Transportföretag',
  fuel_supplier: 'Drivmedelsleverantör/Laddoperatör',
  land_owner: 'Markägare',
  public_actor: 'Offentlig aktör',
}
const type = {
  demand: 'Efterfrågan på laddning/drivmedel',
  supply: 'Delning av egna laddplatser/drivmedel',
  planned_investment:
    'Dela planerad investering av fossilfri fordonsflotta (för att visa behov av framtida infrastruktur)',
}
const offer = {
  fuel: 'Erbjuda drivmedel',
  charging_points: 'Erbjuda laddplatser',
  internal_outlets: 'Söker mark för etablering',
}
const offerPublicActor = {
  upcoming_requirements: 'Ge tidig signal om kommande krav inom upphandling av transporter',
  alternative_fuels: 'Hänvisa till infrastrukturplan för alternativa drivmedel och/eller laddning',
}
const requirementOptions = {
  accessible_alternative_outlets:
    'Ange tillgänglig platser för alternativa drivmedel eller laddplats',
  contact_outlets_supply: 'Få kontakt med laddoperatörer och/eller leverantörer av drivmedel',
}

const requirementLabels = {
  accessible_alternative_outlets:
    'Har tillgängliga platser för alternativa drivmedel eller laddplats',
  contact_outlets_supply: 'Vill ha kontakt med laddoperatörer och/eller leverantörer av drivmedel',
}
const fuelType = {
  el: 'El',
  hvo100: 'HVO100',
  hydrogen: 'Vätgas',
  biogas: 'Biogas',
  ethanol: 'Etanol',
  other: 'Annat',
}
const fuelTypeGroup = {
  electricity: 'El',
  gas: 'Gas',
  liquid: 'Flytande fossilfritt',
}
const accessibility = {
  public: 'Publik',
  non_public: 'Depå/icke-publik',
  semi_public: 'Semi-publik',
}
const vehicleType = {
  car: 'Personbil',
  truck: 'Lätt lastbil',
  heavy_truck: 'Tung lastbil',
}
const refill = {
  week: 'Veckovis',
  month: 'Månadsvis',
  quarter: 'Kvartalsvis',
  other: 'Inte relevant/Vet ej',
}
const definitions = {
  vehicle_type: 'Fordonstyp',
  fuel_type: 'Drivmedel',
  liquid_fuel_type: 'Flytande fossilfria drivmedel',
  gas_fuel_type: 'Gasformiga fossilfria drivmedel',
  charging_infrastructure: 'Laddinfrastruktur',
  explore: 'Utforska',
}
const capacity = {
  '22': 'Depå (upp till 22 kW)',
  '50': 'Semi-snabb laddning (22 - 49 kW)',
  '149': 'Snabbladdning (50 - 149 kW)',
  '150': 'Tunga fordon (150 - 349 kW)',
  '350': 'Tunga fordon (350+ kW)',
}

const areaType = {
  municipality: 'Kommun',
  county: 'Län',
}

const keyGroups: { [key: string]: { [key: string]: string } } = {
  operator,
  type,
  offer,
  offerPublicActor,
  requirementOptions,
  requirementLabels,
  fuelType,
  accessibility,
  vehicleType,
  refill,
  capacity,
  fuelTypeGroup,
  areaType,
  definitions,
}

export async function useKeyTranslator(keyGroup: keyof typeof keyGroups) {
  const stationStore = useStationDataStore()
  const activeKeyGroup = ref<{ [key: string]: string }>({})
  const keyValueOptions = ref<{ value: string | number; label: string }[]>([])
  if (keyGroup === 'fuelType') {
    await stationStore.initFuelTypes()
    const fuelTypeOptions = computed(() => {
      return stationStore.fuelTypes.map((fuelType) => {
        return {
          label: fuelType.title,
          value: fuelType.id,
        }
      })
    })

    activeKeyGroup.value = fuelTypeOptions.value.reduce((acc, cur) => {
      acc[cur.value] = cur.label
      return acc
    }, {} as typeof activeKeyGroup.value)

    keyValueOptions.value = fuelTypeOptions.value
  } else {
    activeKeyGroup.value = keyGroups[keyGroup]
    keyValueOptions.value = Object.keys(activeKeyGroup.value).map((key) => ({
      value: key,
      label: activeKeyGroup.value[key],
    }))
  }

  function keyTranslator(key: string | undefined | number) {
    if (!key) return ''
    return activeKeyGroup.value[key] || ''
  }

  return { keyTranslator, keyValueOptions }
}
