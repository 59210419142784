import get from 'lodash/get'

type SortOrder = 'asc' | 'desc'

export function stringSortOnPropertyPath<T>(propertyPath: string, sortOrder: SortOrder = 'asc') {
  const sortOrderFactor = sortOrder === 'desc' ? -1 : 1

  return function (a: T, b: T) {
    const valueA = get(a, propertyPath)
    const valueB = get(b, propertyPath)

    if (valueA < valueB) {
      return -1 * sortOrderFactor
    }
    if (valueA > valueB) {
      return 1 * sortOrderFactor
    }

    return 0
  }
}
