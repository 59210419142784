import plugin_vue3_A0OWXRrUgq from "/github/workspace/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/github/workspace/frontend/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/github/workspace/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/github/workspace/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/github/workspace/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import strapi_plugin_35gTOGMBRa from "/github/workspace/frontend/node_modules/@nuxtjs/strapi/dist/runtime/strapi.plugin.mjs";
import plugin_6XQNHTIqbg from "/github/workspace/frontend/node_modules/@sfxcode/nuxt-primevue/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/github/workspace/frontend/.nuxt/unocss.mjs";
import formkitPlugin_pZqjah0RUG from "/github/workspace/frontend/.nuxt/formkitPlugin.mjs";
import chunk_reload_client_UciE0i6zes from "/github/workspace/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import chartjs_1wC9lAM0Tp from "/github/workspace/frontend/plugins/chartjs.ts";
import markdownit_fsFV40uZ9C from "/github/workspace/frontend/plugins/markdownit.ts";
import openlayers_client_nbcYPdMR3m from "/github/workspace/frontend/plugins/openlayers.client.ts";
import umami_5KgQP7X3nR from "/github/workspace/frontend/node_modules/nuxt-umami/plugins/umami.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  strapi_plugin_35gTOGMBRa,
  plugin_6XQNHTIqbg,
  unocss_MzCDxu9LMj,
  formkitPlugin_pZqjah0RUG,
  chunk_reload_client_UciE0i6zes,
  chartjs_1wC9lAM0Tp,
  markdownit_fsFV40uZ9C,
  openlayers_client_nbcYPdMR3m,
  umami_5KgQP7X3nR
]