
import { defuFn } from '/github/workspace/frontend/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "umami": {
    "id": "",
    "host": "",
    "version": 1,
    "domains": "",
    "debug": false,
    "autoTrack": true,
    "ignoreDnt": true,
    "useDirective": false,
    "customEndpoint": "/",
    "ignoreLocalhost": true
  }
}

import cfg0 from "/github/workspace/frontend/node_modules/nuxt-umami/app.config.ts"

export default /* #__PURE__ */ defuFn(cfg0, inlineConfig)
