import { Extent } from 'ol/extent'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { colors } from './../composables/colors'
import { HeatMapSettings, HeatmapData, LayerControl, MapAreaTypes } from '~/types/map'

const defaultHeatmapSettings: HeatMapSettings = {
  dataSuffix: '',
  areaType: 'municipality',
  legendColorSteps: 4,
  colors: colorsForHeatmap,
}
export const useMapStore = defineStore({
  id: 'map',

  state: () => ({
    appVersion: useRuntimeConfig().public.APP_VERSION,
    products: [],
    activeCountyId: null as string | null,
    activeMunicipalityId: null as string | null,
    heatmapMunicipalityData: [] as HeatmapData[],
    heatmapCountyData: [] as HeatmapData[],
    heatmapSettings: defaultHeatmapSettings as HeatMapSettings,
    areaTypes: ['municipality', 'county'] as MapAreaTypes[],
    focusExtent: null as Extent | null,
    activeExtent: undefined as Extent | undefined,
    activeLayers: [] as LayerControl[],
    layerVisibilityOveride: {} as { [key: string]: boolean },
  }),
  actions: {
    setActiveCounty(countyId: string) {
      this.activeCountyId = countyId
    },
    clearActiveCounty() {
      this.activeCountyId = null
    },
    setActiveMunicipality(municipalityId: string) {
      this.activeMunicipalityId = municipalityId
    },
    clearActiveMunicipality() {
      this.activeMunicipalityId = null
    },
    setHeatmapMunicipalityData(data: HeatmapData[]) {
      this.heatmapMunicipalityData = data
    },
    setHeatmapCountyData(data: HeatmapData[]) {
      this.heatmapCountyData = data
    },
    setHeatmapSettings(settings: HeatMapSettings) {
      this.heatmapSettings = { ...defaultHeatmapSettings, ...settings }
    },
    resetHeatmapSettings() {
      this.heatmapSettings = defaultHeatmapSettings
    },
    updateFocusExtent(data: Extent) {
      this.focusExtent = data
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMapStore, import.meta.hot))
}
