import { acceptHMRUpdate, defineStore } from 'pinia'
import { Strapi4ResponseData } from '@nuxtjs/strapi/dist/runtime/types'
import { useStationDataStore } from './stations'
import { updateFilterValues } from './utils/filter-util'
import { useGeoDataStore } from '~/stores/geoData'
import { Filter, FilterSettings } from '~/types/filters'
import { MatchmakingItem, MatchmakingPost } from '~/types/types'

export const matchmakingFilterSettings: FilterSettings = {
  fuelTypes: {
    identifier: 'fuelTypes',
    dataKey: 'fuel_type',
    label: 'Drivmedel',
    type: 'idInArray',
    selectType: 'multipleSelect',
    options: [],
  },
  vehicleType: {
    identifier: 'vehicleType',
    dataKey: 'vehicle_type',
    label: 'Fordonstyp',
    type: 'keyInArray',
    selectType: 'multipleSelect',
    options: [],
  },
  type: {
    identifier: 'type',
    dataKey: 'type',
    label: 'Typ',
    type: 'rootKey',
    selectType: 'multipleSelect',
    options: [],
  },
  accessibility: {
    identifier: 'accessibility',
    dataKey: 'accessibility',
    label: 'Tillgänglighet',
    type: 'rootKey',
    selectType: 'multipleSelect',
    options: [],
  },
  county: {
    identifier: 'county',
    dataKey: 'county',
    label: 'Län',
    type: 'rootKey',
    selectType: 'multipleSelect',
    options: [],
  },
  fuelTypeGroup: {
    identifier: 'fuelTypeGroup',
    dataKey: 'fuel_type',
    label: 'Drivmedelstyp',
    type: 'fuelTypeGroup',
    selectType: 'multiple',
    options: [],
  },
  operator: {
    identifier: 'operator',
    dataKey: 'operator',
    label: 'Aktör',
    type: 'rootKey',
    selectType: 'multipleSelect',
    options: [],
  },
  slug: {
    identifier: 'slug',
    dataKey: 'slug',
    label: 'Slug',
    type: 'rootKey',
    selectType: 'multipleSelect',
    options: [],
  },
}

export const useMatchmakingDataStore = defineStore({
  id: 'matchmakingData',
  state: () => ({
    posts: [] as MatchmakingItem[],
    locations: [] as MatchmakingItem[],
    filters: [] as Filter[],
    filterSettings: matchmakingFilterSettings,
    pending: false,
  }),
  getters: {
    postsWithFilters({ filters, filterSettings, posts }): MatchmakingItem[] {
      const { evalFiltersOnItem } = useGlobalFilters()
      return posts.filter((post) => {
        return evalFiltersOnItem(post, filters, filterSettings)
      })
    },
    locationsWithFilters({ filters, filterSettings, locations }): MatchmakingItem[] {
      const { evalFiltersOnItem } = useGlobalFilters()
      return locations.filter((location) => {
        return evalFiltersOnItem(location, filters, filterSettings)
      })
    },
    getFiltervalues:
      ({ filters }) =>
      (identifier: string) => {
        return filters.find((filter) => filter.filterIdentifier === identifier)?.values || []
      },
    getSelectedFilters({
      filters,
      filterSettings,
    }): { val: string; identifier: string; label: string }[] {
      const selectedFilters = filters.reduce((accumulator, item) => {
        if (item.values) {
          const extendedValues = item.values.map((val) => {
            return {
              val,
              identifier: item.filterIdentifier,
              label: filterSettings[item.filterIdentifier].options.find((opt) => opt.value === val)?.label,
            }
          })
          return accumulator.concat(extendedValues)
        }
        return accumulator
      }, [] as any[])
      return selectedFilters.length ? selectedFilters : []
    },

  },
  actions: {
    async initData() {
      if (this.posts.length > 0 || this.pending) return
      this.pending = true
      const { find } = useStrapi<MatchmakingPost>()

      const stationStore = useStationDataStore()
      await stationStore.initFuelTypes()
      const response = await find('matchmakings', {
        pagination: { start: 0, limit: 20000 },
        filters: {
          end_date: {
            $gte: new Date(),
          },
        },
      })

      const data = response.data.map((matchmakingResponse) => {
        return parseMatchmakingItem(matchmakingResponse)
      })

      this.filterSettings.fuelTypes.options = stationStore.fuelTypeFilterOptions
      this.filterSettings.fuelTypeGroup.options = stationStore.fuelTypeGroupFilterOptions
      const { keyValueOptions: vehicleTypeOptions } = await useKeyTranslator('vehicleType')
      this.filterSettings.vehicleType.options = vehicleTypeOptions.value
      const { keyValueOptions: typeOptions } = await useKeyTranslator('type')
      this.filterSettings.type.options = typeOptions.value
      const { keyValueOptions: accessibilityOptions } = await useKeyTranslator('accessibility')
      this.filterSettings.accessibility.options = accessibilityOptions.value
      const { keyValueOptions: operatorOptions } = await useKeyTranslator('operator')
      this.filterSettings.operator.options = operatorOptions.value

      const geoDataStore = useGeoDataStore()
      await geoDataStore.initCounties()
      const counties = geoDataStore.counties.map((county) => {
        return {
          label: county.name,
          value: county.county_code,
        }
      })
      this.pending = false
      this.filterSettings.county.options = counties
      this.locations = parseLocations(data)
      this.posts = data as MatchmakingItem[]

      return data
    },
    async fetchSinglePost(slug: string) {
      const post = this.posts.find((post) => post.slug === slug)
      if (post) return post

      const { find } = useStrapi<MatchmakingPost>()

      const response = await find<MatchmakingPost>('matchmakings', {
        filters: {
          slug: {
            $eq: slug,
          },
        },
      })

      const postData = response.data[0]
      if (!postData) return null
      const matchmakingItem = parseMatchmakingItem(postData)
      return matchmakingItem
    },
    updateFilter(filterSettingIdentifier: string, values: string[]) {
      updateFilterValues(filterSettingIdentifier, values, this)
    },
    resetFilters() {
      this.filters = []
    },
  },
})

function parseMatchmakingItem(matchmakingResponse: Strapi4ResponseData<MatchmakingPost>) {
  const vehicleType = matchmakingResponse.attributes?.vehicle_type
    ? matchmakingResponse.attributes?.vehicle_type?.split(',').map((item) => item)
    : []

  const fuelType = matchmakingResponse.attributes?.fuel_type
    ? matchmakingResponse.attributes?.fuel_type?.split(',').map((item) => Number(item))
    : []

  const capacity = matchmakingResponse.attributes?.capacity
    ? matchmakingResponse.attributes?.capacity?.split(',')
    : []

  const matchmakingItem: MatchmakingItem = {
    ...matchmakingResponse.attributes,
    id: matchmakingResponse.id,
    vehicle_type: vehicleType,
    fuel_type: fuelType,
    capacity,
  }

  return matchmakingItem
}
function parseLocations(posts: MatchmakingItem[]) {
  return posts.reduce((acc, post) => {
    if (!Array.isArray(post.locations)) return acc
    post.locations.forEach((location) => {
      acc.push({ ...post, lat: location.lat, lng: location.lng } as MatchmakingItem)
    })
    return acc
  }, [] as MatchmakingItem[])
}
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMatchmakingDataStore, import.meta.hot))
}
